import { modelBrandService } from 'services';
import { FIELD_VALIDATION, FilterTypes, modelsOptionsMapper } from '@bs/techconnect-ui';

export const REPORTS = {
  // drivingStyle: 'Стиль вождения',
  // criticalIssues: 'Критические неполадки',
  // averageFuelConsumption: 'График изменения среднего расхода на 100 км от пробега',
  checkEngine: 'Количество транспортных средств с Check Engine',
  batteryDegradation: 'Деградация аккумулятора от пробега',
  // dailyMileage: 'Отчет по эксплуатации транспортных средств',
};

export const DEFAULT_FILTERS = [
  {
    key: 'modelId',
    title: 'Марка / Модель',
    placeholder: 'Не выбрано',
    type: FilterTypes.s,
    source: modelBrandService.getModels,
    mapper: modelsOptionsMapper,
  },
  { key: 'vin', title: 'VIN', validate: v => FIELD_VALIDATION.DURATION(v, 3, 17) || '' },
];

export const DATE_FILTER = [{ key: ['from', 'to'], type: FilterTypes.r }];
