import { useAuth } from 'react-oidc-context';
import { Button, turnOffEvent } from '@bs/techconnect-ui';
import { ReactComponent as Logo } from 'icons/logo.svg';

import styles from './Login.module.css';

export const Login = () => {
  const auth = useAuth();

  const handleLogin = e => {
    turnOffEvent(e);
    auth.signinRedirect();
  };

  return (
    <div className={styles['wrapper']}>
      <Logo className={styles['logo']} />
      <header>
        techconnect
        <br />
        <b>OEM Portal</b>
      </header>
      <span>Авторизация</span>

      <Button onClick={handleLogin}>Войти</Button>
    </div>
  );
};
