import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { modelBrandService } from 'services';
import { useSuccess } from 'helpers';
import { Input, Button, updateFormErrors, ERRORS, ButtonTypes } from '@bs/techconnect-ui';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from './MaintenanceForm.module.css';

export const MaintenanceForm = () => {
  const { maintenanceId } = useParams();
  const location = useLocation();
  const { brandData } = location.state;
  const { maintenance } = brandData || {};
  const { setSuccess } = useSuccess();

  const navigate = useNavigate();
  const back = () => navigate('/maintenance');
  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm({ mode: 'onTouched', defaultValues: maintenance });

  const [loading, setLoading] = useState(false);
  const [exitModal, setExitModal] = useState(false);

  useEffect(() => {
    if (maintenance) reset(maintenance);
  }, [maintenanceId, maintenance, reset]);

  const save = form => {
    const maintenance = { mileage: +form.mileage, period: +form.period };
    setLoading(true);
    modelBrandService
      .updateModel({ ...brandData, maintenance }, maintenanceId)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data?.maintenance, form, setError);
        if (status === 200) {
          setSuccess('Данные сохранены');
          reset(data?.maintenance);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={styles['wrapper']}>
      <header className={styles['header']}>
        <Button
          className={styles['back-btn']}
          variant={ButtonTypes.IR}
          onClick={isDirty ? () => setExitModal(true) : back}
        >
          <Back />
        </Button>
        <h3>Редактирование регламента ТО</h3>
        <Button className={styles['submit-btn']} disabled={loading} onClick={() => handleSubmit(save)()}>
          Сохранить изменения
        </Button>
      </header>
      <div className={styles['fields-wrapper']}>
        <Input
          className={styles['field']}
          type="number"
          label="Периодичность ТО по пробегу(км)"
          register={register('mileage', {
            required: ERRORS.REQUIRED('Периодичность ТО по пробегу'),
            min: { value: 1, message: ERRORS.DURATION(1) },
          })}
          error={errors.mileage}
          disabled={loading}
        />
        <Input
          className={styles['field']}
          type="number"
          label="Периодичность ТО по времени(мес)"
          register={register('period', {
            required: ERRORS.REQUIRED('Периодичность ТО по времени'),
            min: { value: 1, message: ERRORS.DURATION(1) },
          })}
          error={errors.period}
          disabled={loading}
        />
      </div>

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={back} />
    </div>
  );
};

MaintenanceForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  maintenance: PropTypes.shape({
    mileage: PropTypes.number,
    period: PropTypes.number,
  }),
  updateModels: PropTypes.func,
};
