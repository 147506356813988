import { ERRORS, FIELD_VALIDATION } from '@bs/techconnect-ui';

export const COLUMNS = [
  { key: 'name', title: 'Название' },
  { key: 'address', title: 'Адрес' },
  { key: 'edit', title: '' },
  { key: 'delete', title: '' },
];

export const DEALERS_FORM = [
  [
    {
      key: 'name',
      title: 'Название',
      options: { required: ERRORS.REQUIRED('название'), validate: v => FIELD_VALIDATION.DURATION(v, 1, 30) },
    },
    { key: 'address', title: 'Адрес', options: { required: ERRORS.REQUIRED('адрес') } },
    { key: 'email', title: 'Email', options: { required: ERRORS.REQUIRED('email'), pattern: FIELD_VALIDATION.EMAIL } },
  ],
  [
    {
      key: 'phone',
      title: 'Телефон',
      options: { required: ERRORS.REQUIRED('телефон'), pattern: FIELD_VALIDATION.PHONE },
    },
    {
      key: 'description',
      title: 'Комментарий',
      options: { validate: v => FIELD_VALIDATION.DURATION(v, 0, 500) },
    },
  ],
];
