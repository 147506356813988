import { useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';

import { vehicleService } from 'services';
import { ROLES, useUser } from 'helpers';
import { ButtonGroup, Button, ButtonTypes, Paper, parseBrandModel, EMPTY, Loader } from '@bs/techconnect-ui';
import { ReactComponent as Arrow } from 'icons/chevron.svg';
import { VehiclePageCurrent } from './VehiclePageCurrent/VehiclePageCurrent';
import { VehiclePageHistory } from './VehiclePageHistory/VehiclePageHistory';
import { VehiclePageMaintenance } from './VehiclePageMaintenance/VehiclePageMaintenance';

import styles from './VehiclePage.module.css';

const TABS = { c: 'current', h: 'history', m: 'maintenance' };
const TABS_TITLE = { current: 'Сейчас', history: 'История', maintenance: 'ТО' };

const VehiclePage = () => {
  const navigate = useNavigate();
  const { vehicleId } = useParams();
  const {
    user: { roles = [] },
  } = useUser();
  const isAdmin = roles.find(r => r === ROLES.admins);
  const method = isAdmin ? vehicleService.getVehicle : vehicleService.getMobileVehicle;

  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [tab, setTab] = useState(TABS.c);

  useEffect(() => {
    setLoading(true);
    method(vehicleId)
      .then(({ data, status }) => {
        if (status === 200) setVehicle(data);
      })
      .finally(() => setLoading(false));
  }, [method, vehicleId]);

  if (loading)
    return (
      <div className="loader-wrapper">
        <Loader />
      </div>
    );
  return (
    <div className={styles['wrapper']}>
      <Paper className={styles['header']}>
        <header>
          <Button className={styles['back']} variant={ButtonTypes.IR}>
            <Arrow onClick={() => navigate('/vehicles')} />
          </Button>
          <h3>Карточка транспортного средства</h3>
        </header>

        <ButtonGroup
          className={styles['tabs']}
          buttons={Object.values(TABS).map(key => ({ key, title: TABS_TITLE[key] }))}
          selected={[tab]}
          onSelect={setTab}
          disabled={loading}
        />
      </Paper>

      <div className={styles[tab + '-grid']}>
        <div className={styles['info']}>
          <header>{parseBrandModel(vehicle?.model)}</header>
          <div className={styles['data']}>
            <span>
              VIN:&nbsp;
              <b>{vehicle?.vin || EMPTY}</b>
            </span>
            <span>
              ESN:&nbsp;
              <b>{vehicle?.device?.esn || EMPTY}</b>
            </span>
            <span>
              DeviceId:&nbsp;
              <b>{vehicle?.device?.deviceId || EMPTY}</b>
            </span>
          </div>
        </div>

        {vehicle.vehicleId && tab === TABS.c && <VehiclePageCurrent vehicle={vehicle} />}
        {vehicle.vehicleId && tab === TABS.h && <VehiclePageHistory vehicle={vehicle} />}
        {vehicle.vehicleId && tab === TABS.m && <VehiclePageMaintenance vehicle={vehicle} />}
      </div>
    </div>
  );
};

export default VehiclePage;
