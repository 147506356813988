import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { vehicleService } from 'services';
import { COLUMNS } from './helpers';
import { Table, Button, ButtonTypes, parseDate } from '@bs/techconnect-ui';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { CreateMaintenance } from './CreateMaintenance';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';

import styles from './VehiclePageMaintenance.module.css';

export const VehiclePageMaintenance = ({ vehicle }) => {
  const { vehicleId } = vehicle || {};

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const fetchMaintenances = useCallback(() => {
    setLoading(true);
    vehicleService
      .getMaintenances(vehicleId)
      .then(({ status, data }) => {
        if (status === 200) {
          setRows(
            data.map((maintenance = {}) => {
              const date = parseDate(maintenance?.date, 'dd/MM/yyyy');
              return { ...maintenance, date };
            }),
          );
        }
      })
      .finally(() => setLoading(false));
  }, [vehicleId]);

  useEffect(() => {
    fetchMaintenances();
  }, [fetchMaintenances]);

  const handler = row => ({
    ...row,
    delete: (
      <Button variant={ButtonTypes.IT} onClick={() => setDeleteModal(row.id)}>
        <Delete />
      </Button>
    ),
  });

  return (
    <>
      <div className={styles['empty']} />
      <div className={styles['wrapper']}>
        <div className={styles['header']}>
          <span>История технического обслуживания</span>
          <Button variant={ButtonTypes.S} onClick={() => setEditModal(vehicleId)}>
            Добавить запись ТО
          </Button>
        </div>

        <Table
          columns={COLUMNS}
          rows={rows}
          loading={loading}
          handler={handler}
          className={styles['table']}
          idKey="id"
        />

        <CreateMaintenance
          className={styles['create-wrapper']}
          open={!!editModal}
          setOpen={() => setEditModal(!editModal)}
          updateList={fetchMaintenances}
          vehicleId={vehicleId}
        />
        <ConfirmModal
          open={deleteModal}
          updateList={fetchMaintenances}
          setOpen={() => setDeleteModal(!deleteModal)}
          method={() => vehicleService.deleteMaintenances(deleteModal)}
          header="Удалить записи о ТО?"
          description="Я понимаю, что восстановить записи о ТО будет невозможно"
        />
      </div>
    </>
  );
};

VehiclePageMaintenance.defaultProps = {
  vehicle: {
    vehicleId: '',
  },
};

VehiclePageMaintenance.propTypes = {
  vehicle: PropTypes.shape({
    vehicleId: PropTypes.string,
  }),
};
