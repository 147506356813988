import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { modelBrandService } from 'services';
import { COLUMNS } from './helpers';

import { Table, Button, ButtonTypes, Loader, Popper, ListFilters, pageSize } from '@bs/techconnect-ui';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Chevron } from 'icons/chevron.svg';

import styles from './Maintenance.module.css';

const Maintenance = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [models, setModels] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingModel, setLoadingModel] = useState({});

  const fetchModels = useCallback(
    id => {
      if (!id) return;
      setLoadingModel({ ...loadingModel, [id]: true });
      modelBrandService
        .getModelsByBrand(id)
        .then(({ status, data }) => {
          if (status === 200 && Array.isArray(data?.items)) {
            setModels({ ...models, [id]: data.items });
          } else {
            setModels({ ...models, [id]: [] });
          }
        })
        .finally(() => setLoadingModel({ ...loadingModel, [id]: false }));
    },
    [loadingModel, models],
  );
  const fetchBrands = useCallback(
    brandId => {
      setLoading(true);
      modelBrandService
        .getBrands({ page, pageSize })
        .then(({ status, data }) => {
          if (status === 200 && Array.isArray(data?.items)) {
            setRows(data.items);
            if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
          }
        })
        .finally(() => setLoading(false));

      fetchModels(brandId);
    },
    [fetchModels, page],
  );

  useEffect(() => {
    fetchBrands();
  }, [page, fetchBrands]);

  const handler = row => ({
    ...row,
    expand: row.model ? (
      ' '
    ) : loadingModel[row.id] ? (
      <Loader className={styles['loader']} size={20} />
    ) : (
      <Button
        variant={ButtonTypes.T}
        onClick={() =>
          Array.isArray(models[row.id]) ? setModels({ ...models, [row.id]: false }) : fetchModels(row.id)
        }
      >
        <Chevron style={{ transform: `rotate(${Array.isArray(models[row.id]) ? 180 : 0}deg)` }} />
      </Button>
    ),
    name: <span style={{ marginLeft: row.model ? '20px' : 0 }}>{row.name}</span>,
    edit: row?.model ? (
      <Popper popup="Редактировать">
        <Button
          variant={ButtonTypes.IT}
          onClick={() => navigate(`/maintenance/${row.id}`, { state: { brandData: row } })}
        >
          <Edit />
        </Button>
      </Popper>
    ) : (
      <span style={{ display: 'none' }}></span>
    ),
  });

  const getRows = () => {
    const result = [];
    rows.forEach(brand => {
      result.push(brand);
      Array.isArray(models[brand.id]) &&
        models[brand.id].forEach(model => {
          result.push({ model: true, brandId: brand.id, ...model });
        });
    });
    return result;
  };

  return (
    <>
      <ListFilters title="Регламенты ТО" />
      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={getRows()}
        totalPages={totalPages}
        loading={loading}
        idKey="id"
        handler={handler}
      />
    </>
  );
};

export default Maintenance;
