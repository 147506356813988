import { useState } from 'react';
import PropTypes from 'prop-types';

import { vehicleService } from 'services';
import { Modal, SelectDate, Button, ButtonTypes, requestDate } from '@bs/techconnect-ui';
import { ReactComponent as Close } from 'icons/cross.svg';

import styles from './VehiclePageMaintenance.module.css';

export const CreateMaintenance = ({ open, setOpen, updateList, vehicleId }) => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());

  const create = () => {
    setLoading(true);
    vehicleService
      .createMaintenance({ date: requestDate({ date }) }, vehicleId)
      .then(({ status }) => {
        if (status === 200) {
          close();
        }
      })
      .finally(() => setLoading(false));
  };

  const close = () => {
    updateList();
    setOpen();
  };

  return (
    <Modal open={open} className={styles['create-wrapper']}>
      <Button variant={ButtonTypes.T} className={styles['create-close']} onClick={close}>
        <Close />
      </Button>
      <header> Создание записи о прохождении ТО</header>
      <SelectDate
        label="Дата ТО"
        className={styles['date-mt']}
        date={date}
        setDate={setDate}
        maxDate={new Date()}
      ></SelectDate>
      <Button className={styles['create-mt']} disabled={loading} onClick={() => create()} loading={loading}>
        Добавить запись
      </Button>
    </Modal>
  );
};

CreateMaintenance.defaultProps = {
  open: false,
  setOpen: () => {},
  updateList: () => {},
  vehicleId: undefined,
};

CreateMaintenance.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updateList: PropTypes.func,
  vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
