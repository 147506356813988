import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { dealerService } from 'services';
import { useSuccess } from 'helpers';
import { DEALERS_FORM } from '../helpers';
import { Button, ButtonTypes, Checkbox, Input, Loader, updateFormErrors } from '@bs/techconnect-ui';
import { AlertModal } from 'components/AlertModal/AlertModal';
import { ReactComponent as Back } from 'icons/chevron.svg';

import styles from './DealersPage.module.css';

export const DealersPage = () => {
  const { dealerId } = useParams();
  const { setSuccess } = useSuccess();
  const isCreate = dealerId === 'new';

  const [loading, setLoading] = useState(false);
  const [exitModal, setExitModal] = useState(false);
  const [models, setModels] = useState([]);
  const [checkedModels, setCheckedModels] = useState([]);

  const navigate = useNavigate();
  const back = () => navigate('/dealers');
  const {
    reset,
    register,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm({ mode: 'onTouched' });

  useEffect(() => {
    setLoading(true);
    dealerService
      .getModels()
      .then(({ status, data }) => {
        if (status === 200) {
          setModels(data.items);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!isCreate && dealerId) {
      dealerService.getDealerById(dealerId).then(({ status, data }) => {
        if (status === 200) {
          reset(data);
          setCheckedModels(data.models.map(model => model.id));
        }
      });
    }
  }, [dealerId, reset, isCreate]);

  const save = form => {
    const { id } = form;
    const body = { ...form, models: checkedModels };
    const method = id ? dealerService.updateDealer : dealerService.createDealer;
    setLoading(true);
    method(body, id)
      .then(({ data, status }) => {
        if (status !== 200) updateFormErrors(data, form, setError);
        if (status === 200) {
          setSuccess(isCreate ? 'Дилерский центр создан' : 'Данные сохранены');
          back();
        }
      })
      .finally(() => setLoading(false));
  };

  const isCheckedModel = id => checkedModels.includes(id);
  const handleChange = (id, isChecked) => {
    if (isChecked) {
      setCheckedModels(prevCheckedModels => [...prevCheckedModels, id]);
    } else {
      setCheckedModels(prevCheckedModels => prevCheckedModels.filter(modelId => modelId !== id));
    }
  };

  return (
    <div className={styles['wrapper']}>
      <header className={styles['header']}>
        <Button
          className={styles['back-btn']}
          variant={ButtonTypes.IR}
          onClick={isDirty ? () => setExitModal(true) : back}
        >
          <Back />
        </Button>
        <h3>{isCreate ? 'Создание' : 'Редактирование'} дилерского центра</h3>
        <Button className={styles['submit-btn']} disabled={loading} onClick={() => handleSubmit(save)()}>
          {isCreate ? 'Создать дилерский центр' : 'Сохранить изменения'}
        </Button>
      </header>

      {loading ? (
        <Loader size={48} className={styles['vehicle-loader']} />
      ) : (
        <div className={styles['body']}>
          {DEALERS_FORM.map((row, index) => (
            <div className={styles['fields-row']} key={index}>
              {row.map(({ key, title, options }) => (
                <Input
                  key={key}
                  label={title}
                  register={register(key, options)}
                  error={errors[key]}
                  className={key === 'description' ? styles['field'] : ''}
                />
              ))}
            </div>
          ))}

          <div className={styles['models-wrapper']}>
            <header>Список доступных моделей</header>
            <div className={styles['models-list']}>
              {models.map(({ id, name, brand }) => (
                <div key={id} className={styles['models-row']}>
                  <span>{`${brand?.name} ${name}`}</span>
                  <Checkbox
                    id={`${id}`}
                    className={styles['model']}
                    checked={isCheckedModel(id)}
                    onChange={e => {
                      handleChange(id, e.target.checked);
                    }}
                    disabled={loading}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <AlertModal open={exitModal} setOpen={() => setExitModal(!exitModal)} action={back} />
    </div>
  );
};
