import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import { NAV_MENU, useError, useSuccess, useUser } from 'helpers';
import Error from 'components/Error/Error';
import { AbilityWrapper, Paper, Popup, PopupTypes } from '@bs/techconnect-ui';
import VehicleConnect from 'components/VehicleConnect/VehicleConnect';
import Vehicles from 'components/Vehicles/Vehicles';
import VehiclePage from 'components/VehiclePage/VehiclePage';
import Notifications from 'components/Notifications/Notifications';
import News from 'components/News/News';
import Dealers from 'components/Dealers/Dealers';
import Reports from 'components/Reports/Reports';
import Maintenance from 'components/Maintenance/Maintenance';
import { MaintenanceForm } from 'components/Maintenance/MaintenanceForm/MaintenanceForm';
import { BatteryDegradation, CheckEngine } from 'components/Reports';
import { DealersPage } from 'components/Dealers/DealersPage/DealersPage';

import styles from './Body.module.css';

const Body = () => {
  //POPUP MESSAGE
  const { error, setError } = useError();
  const { success, setSuccess } = useSuccess();
  const [popup, setMessage] = useState({ message: '' });
  const { user } = useUser();

  const location = useLocation();
  const { pathname } = location;
  const path = pathname.split('/')[1];
  const isReport = path === 'reports';

  useEffect(() => {
    if (error) {
      setMessage({ message: error, success: PopupTypes.E });
      setTimeout(() => setMessage(''), 6 * 1000);
    }
    return () => {
      setError('');
    };
  }, [error, setError]);

  useEffect(() => {
    if (success) {
      setMessage({ message: success, variant: PopupTypes.S });
      setTimeout(() => setMessage(''), 6 * 1000);
    }
    return () => {
      setSuccess('');
    };
  }, [success, setSuccess]);

  const cls = [styles['wrapper']];
  if (isReport) cls.push(styles['border']);

  return (
    <Paper className={cls.join(' ')}>
      <Routes>
        <Route element={<AbilityWrapper nav_menu={NAV_MENU} user={user} />}>
          <Route path="/" element={<Vehicles />} />
          <Route path="vehicleCreate" element={<VehicleConnect />} />
          <Route path="vehicles" element={<Vehicles />} />
          <Route path="vehicles/:vehicleId" element={<VehiclePage />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="news" element={<News />} />
          <Route path="dealers" element={<Dealers />} />
          <Route path="dealers/:dealerId" element={<DealersPage />} />

          <Route path="maintenance" element={<Maintenance />} />
          <Route path="maintenance/:maintenanceId" element={<MaintenanceForm />} />

          <Route path="reports" element={<Reports />}>
            <Route index element={<CheckEngine />} />
            <Route path="batteryDegradation" element={<BatteryDegradation />} />
            <Route path="checkEngine" element={<CheckEngine />} />
          </Route>
        </Route>

        <Route path="*" element={<Error />} />
      </Routes>

      <Popup {...popup} />
    </Paper>
  );
};

export default Body;
