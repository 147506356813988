import { useState } from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_FILTERS } from '../helpers';
import { Button, Input, Select, RangeDate, FilterTypes } from '@bs/techconnect-ui';

import styles from './ReportsFilters.module.css';

export const ReportsFilters = ({ title, filters, updateList }) => {
  const [filtersInput, setFiltersInput] = useState({});
  const isFiltersValue = Object.values(filtersInput).some(value => !!value);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const apply = () => {
    updateList(filtersInput);
  };

  const filterHandler = (key, value, validate) => {
    setFiltersInput({ ...filtersInput, [key]: value });
    setTouched({ ...touched, [key]: true });
    const message = validate && validate(value, filtersInput);
    setErrors(prev => ({ ...prev, [key]: typeof message === 'string' ? message : false }));
  };

  const input = ({ key, title, validate }) => {
    const error = touched[key] && errors[key] ? { message: errors[key] } : undefined;
    return (
      <Input
        key={key}
        label={title}
        value={filtersInput[key] || ''}
        onChange={({ target }) => filterHandler(key, target.value, validate)}
        error={error}
      />
    );
  };

  const select = ({ key, title, list, source, mapper }) => {
    const error = touched[key] && errors[key] ? { message: errors[key] } : undefined;
    return (
      <Select
        key={key}
        label={title}
        list={list}
        source={source}
        mapper={mapper}
        selected={filtersInput[key] || ''}
        onSelect={value => setFiltersInput({ ...filtersInput, [key]: value })}
        error={error}
      />
    );
  };

  const range = ({ key, title }) => {
    const error = touched[key] && errors[key] ? { message: errors[key] } : undefined;
    return (
      <RangeDate
        key={key.join('-')}
        placeholder={title}
        startDate={filtersInput[key[0]] || ''}
        endDate={filtersInput[key[1]] || ''}
        setDateRange={([startDate, endDate]) => {
          const defaultDate = new Date(2000, 0, 1);
          startDate = startDate?.toString() === defaultDate.toString() ? '' : startDate;
          setFiltersInput({
            ...filtersInput,
            [key[0]]: startDate,
            [key[1]]: endDate,
          });
        }}
        error={error}
      />
    );
  };

  const renderFilter = ({ type, ...other }) => {
    if (!type) return input(other);
    if (type === FilterTypes.s) return select(other);
    if (type === FilterTypes.r) return range(other);
  };

  return (
    <div className={styles['header']}>
      <header>
        <span>{title}</span>
      </header>
      <div className={styles['input-wrapper']}>
        {[...filters, ...DEFAULT_FILTERS].map(renderFilter)}
        <Button className={styles['apply']} onClick={apply} disabled={!isFiltersValue}>
          Сформировать отчет
        </Button>
      </div>
    </div>
  );
};

ReportsFilters.defaultProps = {
  updateList: () => {},
  filters: [],
  title: '',
  inputStyles: '',
};

ReportsFilters.propTypes = {
  updateList: PropTypes.func,
  filters: PropTypes.array,
  title: PropTypes.string,
  inputStyles: PropTypes.string,
};
