import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { vehicleService } from 'services';
import { useUser, ROLES } from 'helpers';
import { COLUMNS, FILTERS, VEHICLE_STATUS } from './helpers';
import {
  parseBrandModel,
  ListFilters,
  Table,
  parseDate,
  pageSize,
  ButtonTypes,
  Popper,
  Button,
} from '@bs/techconnect-ui';
import { ReactComponent as VehicleCard } from 'icons/vehicleCard.svg';

import styles from './Vehicles.module.css';

const Vehicles = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});

  const { user } = useUser();
  const roles = user?.roles || [];
  const dealerOrOem = roles.find(r => r === ROLES.oem || r === ROLES.dealer);
  const method = dealerOrOem ? vehicleService.getMobileVehicles : vehicleService.getVehicles;
  const fetchVehicles = useCallback(() => {
    setLoading(true);
    method({ page, pageSize, ...filters, withState: true })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(
            data.items.map((vehicle = {}) => {
              const brandModel = parseBrandModel(vehicle?.model);
              const lastActivity = parseDate(vehicle?.state?.lastActivity);
              const status = VEHICLE_STATUS[vehicle?.state?.status];
              const saleDate = parseDate(vehicle?.saleDate, 'dd.MM.yyyy');
              const lastMaintenanceDate = parseDate(vehicle?.maintenance?.lastMaintenanceDate, 'dd.MM.yyyy');
              const nextMaintenanceInMileage = vehicle?.maintenance?.nextMaintenanceInMileage;
              const nextMaintenanceDate = parseDate(vehicle?.maintenance?.nextMaintenanceDate, 'dd.MM.yyyy');
              return {
                ...vehicle,
                brandModel,
                lastActivity,
                status,
                saleDate,
                lastMaintenanceDate,
                nextMaintenanceInMileage,
                nextMaintenanceDate,
              };
            }),
          );
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
  }, [filters, method, page]);

  useEffect(() => {
    fetchVehicles();
  }, [page, filters, fetchVehicles]);

  const handler = row => ({
    ...row,
    vehicleCard: (
      <Popper popup={'Карточка транспортного средства'}>
        <Button variant={ButtonTypes.IT} onClick={() => navigate(`/vehicles/${row?.vehicleId}`)}>
          <VehicleCard />
        </Button>
      </Popper>
    ),
  });

  return (
    <>
      <ListFilters
        title="Транспортные средства"
        fields={FILTERS}
        filters={filters}
        setFilters={setFilters}
        updateList={fetchVehicles}
      />

      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        loading={loading}
        idKey="vehicleId"
        handler={handler}
      />
    </>
  );
};

export default Vehicles;
