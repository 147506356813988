import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useError } from 'helpers';
import { errorHandler } from '@bs/techconnect-ui';
import { ReactComponent as Options } from 'icons/options.svg';

import styles from './ReportsPrest.module.css';

export const ReportsPreset = ({ icon, text }) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const { error, setError } = useError();

  useEffect(() => {
    if (error) {
      setErrorMessage(errorHandler(error).split(':')[1]);
    }
    return () => {
      setError(false);
      setErrorMessage(false);
    };
  }, [setError, error]);

  const cls = [styles['default-reports']];
  if (errorMessage) cls.push(styles['error']);
  return (
    <div className={cls.join(' ')}>
      {icon}
      <span>{errorMessage || text}</span>
    </div>
  );
};

ReportsPreset.defaultProps = {
  icon: <Options />,
  text: (
    <>
      <b className={styles['bold-text']}>Выберите условия</b>
      <br />
      Для отображения отчета выберите необходимый диапазон времени и другие условия.
    </>
  ),
};

ReportsPreset.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string,
};
