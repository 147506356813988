import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { dealerService } from 'services';
import { COLUMNS } from './helpers';
import { ButtonTypes, Button, Table, Popper, pageSize, ListFilters } from '@bs/techconnect-ui';
import { ReactComponent as Edit } from 'icons/edit.svg';
import { ReactComponent as Delete } from 'icons/delete.svg';
import { ConfirmModal } from 'components/ConfirmModal/ConfirmModal';

import styles from './Dealers.module.css';

const Dealers = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);

  const fetchDealers = useCallback(() => {
    setLoading(true);
    dealerService
      .getDealers({ page, pageSize })
      .then(({ status, data }) => {
        if (status === 200 && Array.isArray(data?.items)) {
          setRows(data.items);
          if (!isNaN(data.totalPages)) setTotalPages(data.totalPages);
        }
      })
      .finally(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    fetchDealers();
  }, [fetchDealers]);

  const handler = row => ({
    ...row,
    edit: (
      <Popper popup="Редактировать">
        <Button
          variant={ButtonTypes.IT}
          onClick={() => {
            navigate(`/dealers/${row?.id}`);
          }}
        >
          <Edit />
        </Button>
      </Popper>
    ),
    delete: (
      <Popper popup="Удалить">
        <Button variant={ButtonTypes.IT} onClick={() => setDeleteModal(row.id)}>
          <Delete />
        </Button>
      </Popper>
    ),
  });

  return (
    <>
      <ListFilters title="Дилерские центры" createHandler={() => navigate('/dealers/new')} updateList={fetchDealers} />
      <Table
        className={styles['table']}
        columns={COLUMNS}
        rows={rows}
        totalPages={totalPages}
        idKey="id"
        handler={handler}
        loading={loading}
      />

      <ConfirmModal
        open={deleteModal}
        updateList={fetchDealers}
        setOpen={() => setDeleteModal(!deleteModal)}
        method={() => dealerService.deleteDealer(deleteModal)}
        header="Удалить дилерский центр?"
        description="Я понимаю, что восстановить дилерский центр будет невозможно"
      />
    </>
  );
};

export default Dealers;
